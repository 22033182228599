const links = [
  {label: 'About Wave', to: '/#about'},
  // { label: 'Features', to: '/#features' },
  {label: 'Quality', to: '/#quality'},
  {label: 'How it works', to: '/#how-it-works'},
  // {label: 'WaveCoin', to: '/wave-coin'},
  {label: 'Rideshare', to: '/rideshare-services'},
  {label: 'Catering Delivery', to: '/catering-delivery'},
  {label: 'WPS', to: '/professional-services'},
  {label: 'Openings', to: '/openings'},
];

export default links;
